import React from 'react';
import { UseDatum } from 'react-usedatum';
import { Lap } from '../shared/CrewTimerTypes';

/**
 * A dummy shim for the results deployment to resolve references from EventTable.
 * The actual implementation is in crewtimer-admin.
 */
export interface LapEditorInfo {
  entryInfo: Lap;
  anchor: any;
}
export const [useLapEditorInfo, setLapEditorInfo] = UseDatum<LapEditorInfo | undefined>(undefined);

const LapEditorPopover = () => {
  return <></>;
};
export default LapEditorPopover;
