// https://stackoverflow.com/questions/34607841/react-router-nav-bar-example
import React, { useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import PrintIcon from '@mui/icons-material/Print';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Linkify from 'react-linkify';
import makeStyles from '@mui/styles/makeStyles';
import logo from './logo.svg';
import './App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useKiosk, useKioskGreen } from './shared/UseKiosk';
import { useTranslation } from 'react-i18next';
import { useSelectedRegatta } from './shared/RegattaState';
import { useInfoText, useLogoURL, usePointsEngine, useRegattaTitle, useTrackingStations } from './shared/UseResults';
import { setPrintPending } from './shared/UsePrintPending';
import { isMobile } from 'react-device-detect';
import { Divider, Stack } from '@mui/material';

const useStyles = makeStyles((/* theme*/) => ({
  grow: {
    flexGrow: 1,
  },
  infoText: {
    marginRight: 8,
  },
  menuButton: {
    width: 40,
    alignSelf: 'flex-end',
  },
  appBar: {
    color: 'rgb(108, 125, 150)',
    backgroundColor: '#f5f5f5',
  },
  logo: {
    marginRight: 8,
  },
  toolbar: {
    backgroundColor: '#ffffff',
    padding: 8,
    paddingTop: '2px',
    paddingBottom: '2px',
    minHeight: 48,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  kioskspace: {
    height: 16,
    backgroundColor: 'transparent',
  },
  kioskgreen: {
    height: 16,
    backgroundColor: 'green',
  },
}));

const Nav = () => {
  const [open, setOpen] = useState(false);
  const touchEl = useRef<any>();
  const navigate = useNavigate();
  const classes = useStyles();
  const [kioskMode] = useKiosk();
  const [kioskGreen] = useKioskGreen();
  const { t } = useTranslation();
  const [infoText] = useInfoText();
  const [regattaTitle] = useRegattaTitle();
  const [regatta] = useSelectedRegatta();
  const [pointsEngine] = usePointsEngine();
  const [trackingStations] = useTrackingStations();
  const location = useLocation();
  const [logoURL] = useLogoURL();
  const trackingPage = location.pathname.startsWith('/tracking/');
  const pointsPage = location.pathname.startsWith('/points/');
  const schedulePage = location.pathname.startsWith('/schedule/');
  const heatsheetPage = location.pathname.startsWith('/heatsheet/') || location.pathname.startsWith('/packet/');
  const resultsPage = location.pathname.startsWith('/regatta/');
  const livestreamPage = location.pathname.startsWith('/live');
  const title = regatta ? regattaTitle : 'CrewTimer Regatta Timing';

  const handleClose = () => {
    setOpen(false);
    touchEl.current = undefined;
  };

  const handleOpen = (e) => {
    setOpen(true);
    touchEl.current = e.currentTarget;
  };

  const onGoHomeClick = () => {
    if (kioskMode) return;
    navigate('/', { replace: true });
  };

  const onPointsClick = () => {
    navigate(`/points/${regatta}`);
  };

  const handleCloseAndGo = (url) => {
    handleClose();
    navigate(url);
  };

  const initiatePrint = () => {
    handleClose();
    setPrintPending(true);
    setTimeout(() => {
      window.print();
      setPrintPending(false);
    }, 100);
  };
  if (livestreamPage) return null;
  return (
    <>
      <AppBar position="sticky" className={classes.appBar} elevation={kioskMode ? 0 : 4}>
        <Toolbar className={classes.toolbar}>
          <img
            className={classes.logo}
            src={logoURL ? logoURL : logo}
            alt="CrewTimer"
            width="auto"
            height="40"
            onClick={onGoHomeClick}
          />
          <Typography variant="h6" color="inherit" className={classes.grow} onClick={onGoHomeClick}>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="inherit" className={classes.infoText}>
            <Linkify>{infoText}</Linkify>
          </Typography>
          {resultsPage && pointsEngine !== 'None' && (
            <Stack alignItems="center" className="noprint">
              <Typography sx={{ fontSize: '0.75rem' }}>{t(`Points`)}</Typography>
              <IconButton
                className={`${classes.menuButton}`}
                color="inherit"
                aria-label="Menu"
                onClick={onPointsClick}
                size="large"
                sx={{ padding: '4px' }}
              >
                <EmojiEventsIcon />
              </IconButton>
            </Stack>
          )}
          {(trackingPage || pointsPage || schedulePage || heatsheetPage) && (
            <IconButton
              className={`${classes.menuButton} noprint`}
              color="inherit"
              aria-label="Menu"
              onClick={() => navigate(`/regatta/${regatta}`, { replace: true })}
              size="large"
            >
              <ListAltIcon />
            </IconButton>
          )}

          {!kioskMode && (
            <>
              {!isMobile && (
                <IconButton
                  key="print"
                  className={`${classes.menuButton} noprint`}
                  color="inherit"
                  aria-label="Menu"
                  onClick={initiatePrint}
                  size="large"
                >
                  <PrintIcon />
                </IconButton>
              )}
              <IconButton
                key="print2"
                className={`${classes.menuButton} noprint`}
                color="inherit"
                aria-label="Menu"
                onClick={handleOpen}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </>
          )}
          <Menu id="render-props-menu" anchorEl={touchEl.current} open={open} onClose={handleClose}>
            {regatta && [
              <MenuItem key="schedule" onClick={() => handleCloseAndGo(`/schedule/${regatta}`)}>
                {t('Schedule')}
              </MenuItem>,
              <MenuItem key="heatsheet" onClick={() => handleCloseAndGo(`/heatsheet/${regatta}`)}>
                {t('Heatsheet')}
              </MenuItem>,
              <MenuItem key="results" onClick={() => handleCloseAndGo(`/regatta/${regatta}`)}>
                {t('Results')}
              </MenuItem>,
              trackingStations.length ? (
                <MenuItem key="tracking" onClick={() => handleCloseAndGo(`/tracking/${regatta}`)}>
                  {t('Tracking')}
                </MenuItem>
              ) : (
                []
              ),
              <Divider key="sep" />,
            ]}

            <MenuItem onClick={() => handleCloseAndGo('/about')}>{t('About')}</MenuItem>
            <MenuItem onClick={() => handleCloseAndGo('/store')}>{t('Store')}</MenuItem>
            <MenuItem onClick={() => handleCloseAndGo('/privacy')}>{t('Privacy Policy')}</MenuItem>
            <MenuItem onClick={() => handleCloseAndGo('/termsofuse')}>{t('Terms of Use')}</MenuItem>
            {!isMobile && (
              <MenuItem key="print" onClick={initiatePrint}>
                {t('Print')}
              </MenuItem>
            )}
          </Menu>
        </Toolbar>
        {kioskMode && <div className={kioskGreen ? classes.kioskgreen : classes.kioskspace} />}
      </AppBar>
      {/* Since AppBar is position=fixed, add in space to lower body below AppBar */}
      {/* <Toolbar className={classes.toolbar} style={{ zIndex: -1, marginTop: 2, marginBottom: 2 }} /> */}
    </>
  );
};
export default Nav;
