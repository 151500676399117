// import { simplePointsCalc } from 'crewtimer-points/dist/esm/src/calculators/SimpleTeamPointsCalc';
import { Box, Typography } from '@mui/material';
import { PointsViewers } from 'crewtimer-points';
import { Event, ResultRegattaInfo, Results } from 'crewtimer-common';
import { useEventResults, useLastUpdatedEvent, useRegattaInfo } from '../shared/UseResults';

export const Points = () => {
  // const points = [{ team: 'Foo', points: 13 }];
  const [results] = useEventResults();
  const [regattaInfo] = useRegattaInfo();
  const [lastUpdatedEvent] = useLastUpdatedEvent();
  // const [value, setValue] = useState('1');

  const resultRecord: Results = {
    P: { S: {}, Waypoints: [] },
    lastUpdatedEvent,
    regattaInfo: (regattaInfo || {}) as unknown as ResultRegattaInfo,
    results: results as unknown as Event[],
  };
  const NullViewer = () => {
    return <></>;
  };
  const PointsViewer = PointsViewers.find((viewer) => viewer.key === regattaInfo?.PointsEngine)?.ui || NullViewer;

  // const handleChange = (_event: SyntheticEvent, newValue: string) => {
  //   setValue(newValue);
  // };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Box sx={{ padding: '2em' }}>
        <PointsViewer results={resultRecord} />
      </Box>
      <Typography
        sx={{ marginTop: '1em', textAlign: 'center', fontSize: '0.8em' }}
      >{`Generated ${new Date().toLocaleString()}`}</Typography>
    </Box>
  );
};
