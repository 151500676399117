import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import {
  TableRow,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Box,
} from '@mui/material';
import { decodeEventName } from 'crewtimer-common';
import EventFilter, {
  gotoSingleEvent,
  useFilteredEventNums,
  useRelatedEventNums,
  useEventFilter,
} from '../shared/EventFilter';
import { EventSummary, useEventSummaryList } from '../shared/UseResults';
import { Check } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useSelectedRegatta } from '../shared/RegattaState';
import { useTranslation } from 'react-i18next';

const styles: { [key: string]: React.CSSProperties } = {
  EventNum: { textAlign: 'center' },
  NumEntries: { textAlign: 'center' },
  Bracket: { textAlign: 'center' },
  Event: { cursor: 'pointer' },
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: 'auto',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const EventSchedule = () => {
  const [eventSummaryList] = useEventSummaryList();
  const [filteredEventNums] = useFilteredEventNums();
  const [relatedEventNums] = useRelatedEventNums();
  const [, setEventFilter] = useEventFilter();
  const [regatta] = useSelectedRegatta();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    setEventFilter('');
  });

  // Key, Label
  let columns: [string, string][] = [
    ['Day', 'Day'],
    ['Start', 'Start'],
    ['EventNum', 'Race'],
    ['Event', 'Event'],
    ['Bracket', 'Stage'],
    ['NumEntries', 'Entries'],
  ];
  const eventList = eventSummaryList.filter((event) => filteredEventNums.includes(event.EventNum));

  let allFA = true;
  let noDays = true;
  let noStarts = true;
  const dispData = eventList.map((event: EventSummary) => {
    const eventName = event.Event.startsWith(event.EventNum)
      ? event.Event.substring(event.EventNum.length)
      : event.Event;
    const data = {
      ...event,
      Bracket: decodeEventName(event.Event, event.EventNum).bracket,
      Event: relatedEventNums.has(event.EventNum) ? `+ ${eventName}` : eventName,
    };

    allFA = allFA && data.Bracket === 'FA';
    noDays = noDays && data.Day === '';
    noStarts = noStarts && data.Start === '';

    if (data.RaceType === 'Info') {
      data.Bracket = '';
      data.EventNum = '';
      data.NumEntries = 0;
    }

    return data;
  });

  columns = columns.filter(
    ([key]) => !((allFA && key === 'Bracket') || (noDays && key === 'Day') || (noStarts && key === 'Start'))
  );

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <EventFilter />
      <Stack alignItems="center">
        <Typography className="noprint" variant="h6">
          {t('Race Schedule')}
        </Typography>
        <TableContainer sx={{ width: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map(([key, text]) => (
                  <TableCell
                    key={key}
                    style={{
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      fontSize: '1em',
                      fontWeight: 'bold',
                      // height: "30px",
                      color: 'black',
                      paddingLeft: '0.5em',
                      paddingRight: '0.5em',
                    }}
                  >
                    {t(text)}
                  </TableCell>
                ))}
                <TableCell className="noprint"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dispData.map((data) => {
                const isInfoEvent = data.RaceType === 'Info';
                const related = relatedEventNums.has(data.EventNum);
                const bg = related ? '#ffff9990' : undefined;
                return (
                  <StyledTableRow
                    key={`${data.EventNum}-${data.Event}-${data.Start}`}
                    onClick={() => {
                      gotoSingleEvent(data.EventNum);
                      navigate(`/regatta/${regatta}`);
                    }}
                    style={{ backgroundColor: isInfoEvent ? '#fff2cc' : undefined }}
                  >
                    {columns.map(([key]) => (
                      <TableCell key={key} style={styles[key]} sx={{ fontSize: '0.9em', background: bg }}>
                        {data[key] || ''}
                      </TableCell>
                    ))}
                    <TableCell className="noprint" sx={{ background: bg }}>
                      {data.Official ? <Check color="success" sx={{ fontSize: '1.2em', padding: 0 }} /> : <></>}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ marginTop: '1em', fontSize: '0.8em' }}>{`${t('Generated')} ${new Date().toLocaleString()}`}</div>
      </Stack>
    </Box>
  );
};
